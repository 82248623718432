import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import AuthConnectionIcon from "../../assets/images/authorized_connection.svg";
import AuthConnectionErrorIcon from "../../assets/images/error_authorized_connection.svg";
import { AccountStatusMessages, AccountUrls } from './enum';
import RedirectMessage from "../../components/redirectMessage";
import {getStatusConnection} from "../../pages/loginMeli/accountConnection/controller";
import {Container, Flex} from "../../components/grid";
import Loading from "../../components/loading";
import sendGaEvents from '../../utils/sendGaEvents';
import { MATCH_MELI } from '../../constants/events';
import { get } from '../../utils/cookies';

const ErrorFallback: React.FC<{ error: Error }> = ({ error }) => {
    window.location.assign('/error?error_handler="error na conexão"');
    return (<div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
    </div>)
};

const AccountConnectionContent: React.FC = () => {
    const { data: status, isLoading, error } = useQuery('accountStatus', getStatusConnection, {
        retry: 3,
        staleTime: 5 * 60 * 1000, // 5 minutes
    });

    useEffect(() => {
        if (!status || !status.isAuthorized) {
            return;
        }

        const url = new URL(window.location.href);
  
        sendGaEvents({
          event: MATCH_MELI,
          userId: get("user_id") || "",
          CADASTRO_NOVO: url.searchParams.has("cadastro"),
          ORIGEM_PLATAFORMA: localStorage.getItem("platformOrigin") || "",
        });
      }, [status]);

    if (isLoading) {
        return (
            <div className="loading">
                <Loading id="loading" ariaLabel="Carregando" show={isLoading}/>
            </div>
        );
    }

    if (error || !status) {
        throw error || new Error('Failed to load account status');
    }

    const handleRedirect = () => {
        const url = new URL(window.location.href);
        if (url.searchParams.has('cadastro')) {
            window.location.assign(AccountUrls.ONBOARDING_URL);        
        } else if (url.searchParams.has('endereco')) {
            window.location.assign(AccountUrls.ENDERECO_URL);        
        } else if (url.searchParams.has('cadastro-responsavel')) {
            window.location.assign(AccountUrls.ONBOARDING_RESPONSAVEL_URL);        
        } else {
            window.location.assign(AccountUrls.DASHBOARD_URL);
        }
    };

    return (
        <RedirectMessage
            titleMessage={status.isAuthorized ? AccountStatusMessages.AUTHORIZED_TITLE : AccountStatusMessages.ERROR_TITLE}
            middleMessage={status.isAuthorized ? AccountStatusMessages.AUTHORIZED_MESSAGE : AccountStatusMessages.ERROR_MESSAGE}
            buttonMessage={status.isAuthorized ? "Ir para o portal" : undefined}
            iconImage={status.isAuthorized ? AuthConnectionIcon : AuthConnectionErrorIcon}
            action={status.isAuthorized ? handleRedirect : undefined}
        />
    );
};

const AccountConnectionStatus: React.FC = () => (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AccountConnectionContent />
    </ErrorBoundary>
);

export default AccountConnectionStatus;