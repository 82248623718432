import { FC } from "react";

import { Card, CardContent } from "@andes/card";
import { Checkbox } from "@andes/checkbox";
import { Text } from "@andes/typography";
import Tooltip from "@andes/tooltip";

import { Flex } from "../../components/grid";
import { IOrderList } from "../../services/orderList/types";
import { RenderOcorrencia } from "./renderOcorrencia";

interface OrdersTableProps {
  dataRows: IOrderList[];
  selectAll: boolean;
  onRowCheckBoxChange: (index: number, checked: boolean) => void;
  onSelectAllChange: (checked: boolean) => void;
  orderListState: {
    isHighLight: boolean;
  };
  hasNfeRequired: (exige_nota: boolean, tem_nota: boolean) => boolean;
  compareDateWithToday: (date: string) => boolean;
}

export const Mobile: FC<OrdersTableProps> = ({
  dataRows,
  selectAll,
  onRowCheckBoxChange,
  onSelectAllChange,
  orderListState,
  hasNfeRequired,
  compareDateWithToday,
}) => {
  return (
    <Flex direction="column" gap={4} className="custom-table-buffering">
      <Flex direction="row" justify="start" align="center">
        <Tooltip
          content={selectAll ? "Desselecionar todos" : "Selecionar todos"}
          trigger="hover"
          type="dark"
        >
          <Checkbox
            className="custom-select-all"
            checked={selectAll}
            onChange={(e) => onSelectAllChange(e.target.checked)}
          />
        </Tooltip>
        <Text>Selecionar todos</Text>
      </Flex>

      {dataRows &&
        dataRows.map((row: IOrderList, index: number) => (
          <Card shadow="elevated">
            <CardContent>
              <Flex direction="column" gap={1}>
                <Flex
                  direction="row"
                  justify="between"
                  align="center"
                  className="mb-1"
                >
                  <Flex gap={2}>
                    {!hasNfeRequired(row.exige_nota, row.tem_nota) ||
                    row.nfe_invalida ? (
                      <Tooltip
                        content="Bloqueado por NFe"
                        trigger="hover"
                        type="dark"
                      >
                        <Checkbox
                          disabled={
                            !hasNfeRequired(row.exige_nota, row.tem_nota) ||
                            row.nfe_invalida
                          }
                          checked={row.select}
                          onChange={(e) =>
                            onRowCheckBoxChange(index, e.target.checked)
                          }
                        />
                      </Tooltip>
                    ) : (
                      <Checkbox
                        disabled={!hasNfeRequired(row.exige_nota, row.tem_nota)}
                        checked={row.select}
                        onChange={(e) =>
                          onRowCheckBoxChange(index, e.target.checked)
                        }
                      />
                    )}
                    <Text component="span" size="s">
                      {row.nome_cliente_dest}
                    </Text>
                  </Flex>
                  <div>
                    {row.cidade_dest} - {row.estado_dest}
                  </div>
                </Flex>
                <hr className="mb-2 mt-1" />
                <Flex direction="column" gap={2}>
                  <Flex direction="row" justify="between">
                    <Text color="secondary">Preço:</Text>
                    <Text>R$ {row.vlr_a_pagar}</Text>
                  </Flex>
                  <Flex direction="row" justify="between">
                    <Text color="secondary">Status:</Text>
                    <Text>
                      {RenderOcorrencia({
                        descricao_ocorrencia: row.descricao_ocorrencia,
                        tem_nota: row.tem_nota,
                        exige_nota: row.exige_nota,
                        nfe_invalida: row.nfe_invalida,
                        numero_cli: row.numero_cli,
                      })}
                    </Text>
                  </Flex>
                  <Flex direction="row" justify="between">
                    <Text color="secondary">Ponto de postagem:</Text>
                    <Flex
                      direction="row"
                      align="center"
                      justify="start"
                      gap={1}
                    >
                      <Tooltip
                        content={
                          row.place.agencia
                            ? "Agência Correios"
                            : "Agência Kangu ou Mercado Livre"
                        }
                        trigger="hover"
                        type="dark"
                        side="topLeft"
                      >
                        <img
                          src={row.place.logo}
                          alt={row.place.name}
                          className={
                            row.place.agencia
                              ? "custom-icon-correios"
                              : "custom-icon-kangu"
                          }
                        />
                      </Tooltip>
                      <Text component="span" size="s">
                        {row.place.name}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex
                    direction="row"
                    justify="between"
                    className={orderListState.isHighLight ? "py-2" : ""}
                  >
                    <Text color="secondary">Postagem a partir de:</Text>
                    <div>
                      <Text
                        className={
                          orderListState.isHighLight
                            ? "highlight-order-list py-2 ps-1 pe-1"
                            : ""
                        }
                      >
                        {compareDateWithToday(row.pickup_date)
                          ? row.pickup_date
                          : "Hoje"}
                      </Text>
                    </div>
                  </Flex>
                </Flex>
              </Flex>
            </CardContent>
          </Card>
        ))}
    </Flex>
  );
};
