interface Row {
  tem_nota: boolean;
  exige_nota: boolean;
  numero_cli: string;
  nfe_invalida: boolean;
  descricao_ocorrencia: string;
}

const hasNfeRequired = (exige_nota: boolean, tem_nota: boolean): boolean => {
  return !exige_nota || tem_nota;
};

const renderNfeNecessaria = (numeroCli: string): JSX.Element => (
  <span className="custom-status">
    <a href={`/seller/meus-envios?numero_pedido=${numeroCli}`}>
      NFe necessária
    </a>
  </span>
);

const renderNfeInvalida = (numeroCli: string): JSX.Element => (
  <span className="custom-status">
    <a href={`/seller/meus-envios?numero_pedido=${numeroCli}`}>NF inválida</a>
  </span>
);

const renderDescricaoOcorrencia = (
  descricaoOcorrencia: string
): JSX.Element => <span>{descricaoOcorrencia}</span>;

export const RenderOcorrencia = ({
  tem_nota,
  exige_nota,
  descricao_ocorrencia,
  nfe_invalida,
  numero_cli,
}: Row) => {
  if (!hasNfeRequired(exige_nota, tem_nota)) {
    return renderNfeNecessaria(numero_cli);
  } else if (nfe_invalida) {
    return renderNfeInvalida(numero_cli);
  } else {
    return renderDescricaoOcorrencia(descricao_ocorrencia);
  }
};
