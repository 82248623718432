/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";
import { useAtom } from "jotai";
import { useI18n } from "nordic/i18n";

import { ProgressIndicatorCircular } from "@andes/progress-indicator-circular";
import { getOrderList } from "../../pages/orderList/controller";
import { IOrderList } from "../../services/orderList/types";
import {
  orderListStateAtom,
  type OrderListStateProps,
} from "../../states/orderList";
import { Web } from "./web";
import { Mobile } from "./mobile";

export const Orders: FC = () => {
  const [dataRows, setDataRows] = useState<IOrderList[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const { i18n } = useI18n();
  const [orderListState, setOrderListState] =
    useAtom<OrderListStateProps>(orderListStateAtom);

  const [searchParams] = useSearchParams();
  const ids = searchParams.get("id");

  const handleGetRows = async () => {
    setLoading(true);
    try {
      const response = await getOrderList(ids as string);

      const { data } = response;

      const ordersArray =
        data && data.listaPedidos && typeof data.listaPedidos === "object"
          ? Object.values(data.listaPedidos)
          : [];

      const result =
        Array.isArray(ordersArray) && ordersArray.length > 0
          ? ordersArray.map((order: IOrderList) => ({
              id: order.id,
              numero: order.numero,
              nome_cliente_dest: order.nome_cliente_dest,
              cidade_dest: order.cidade_dest,
              estado_dest: order.estado_dest,
              peso_merc: order.peso_merc,
              qtd_vol: order.qtd_vol,
              vlr_a_pagar: order.vlr_a_pagar,
              descricao_ocorrencia: order.descricao_ocorrencia,
              place: {
                id: order.place?.id || 0,
                address: order.place?.address || "",
                name: order.place?.name || "",
                logo: order.place?.logo || "",
                agencia: order.place?.agencia || false,
              },
              pickup_date: order.pickup_date,
              exige_nota: order.exige_nota,
              tem_nota: order.tem_nota,
              numero_cli: order.numero_cli,
              id_transp: order.id_transp,
              select:
                hasNfeRequired(order.exige_nota, order.tem_nota) &&
                !order.nfe_invalida,
              nfe_invalida: order.nfe_invalida,
            }))
          : [];

      // verify if has different places checked
      differentPlacesChecked(result);

      // Verify if has pickup date greater today
      const hasHighLight = result.some((order: IOrderList) => {
        return compareDateWithToday(order.pickup_date);
      });

      setOrderListState((prev) => ({
        ...prev,
        isHighLight: hasHighLight,
        totalValue: handleGetTotalValueCart(result),
      }));

      setDataRows(result);
      setLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setLoading(false);
    }
  };

  const handleSelectAllChange = (checked: boolean) => {
    setSelectAll(checked);
    setDataRows((prevRows: IOrderList[]) =>
      prevRows.map((row) => ({
        ...row,
        select: hasNfeRequired(row.exige_nota, row.tem_nota) ? checked : false,
      }))
    );
  };

  const handleRowCheckBoxChange = (index: number, checked: boolean) => {
    if (Array.isArray(dataRows) && dataRows.length > 0) {
      const newRows = [...dataRows];
      newRows[index].select = hasNfeRequired(
        newRows[index].exige_nota,
        newRows[index].tem_nota
      )
        ? checked
        : false;
      setDataRows(newRows);
      setSelectAll(newRows.every((row) => row.select));

      // Verify if has different places checked
      differentPlacesChecked(newRows);
    }
  };

  const differentPlacesChecked = (data: IOrderList[]) => {
    const differentPlacesChecked = data
      .filter((row) => row.select)
      .map((row) => row.place)
      .filter(
        (place, index, self) =>
          self.findIndex((p) => p.id === place.id) === index
      );

    setOrderListState((prev) => ({
      ...prev,
      places: differentPlacesChecked,
    }));
  };

  const hasNfeRequired = (exige_nota: boolean, tem_nota: boolean): boolean => {
    return !exige_nota || tem_nota;
  };

  const compareDateWithToday = (dateString: string): boolean => {
    const [day, month, year] = dateString.split("/").map(Number);
    const inputDate = new Date(year, month - 1, day);

    const today = new Date();
    const todayDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    return inputDate > todayDate;
  };

  const handleGetTotalValueCart = (orders: IOrderList[]) => {
    const totalValue = orders.reduce((acc, order) => {
      if (order.select) {
        const value = parseFloat(order.vlr_a_pagar.replace(",", "."));
        return acc + value;
      }
      return acc;
    }, 0);

    return totalValue;
  };

  useEffect(() => {
    const totalOrders = dataRows.length;
    const idOrdersSelected = dataRows
      .filter((row) => row.select)
      .map((row) => row.id);

    setOrderListState((prev) => ({
      ...prev,
      totalOrders,
      totalSelectedOrders: idOrdersSelected.length,
      idOrdersSelected,
      totalValue: handleGetTotalValueCart(dataRows),
    }));
  }, [dataRows]);

  useEffect(() => {
    handleGetRows();
  }, []);

  // verify if is mobile with window resize and innerWidth
  const isMobileScreen = window.innerWidth < 768;
  const verifySize = () => {
    setOrderListState((prev) => ({
      ...prev,
      isMobile: isMobileScreen,
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      verifySize();
    };

    window.addEventListener("resize", handleResize);
    verifySize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {orderListState.isMobile ? (
        <Mobile
          dataRows={dataRows}
          selectAll={selectAll}
          onRowCheckBoxChange={handleRowCheckBoxChange}
          onSelectAllChange={handleSelectAllChange}
          orderListState={orderListState}
          hasNfeRequired={hasNfeRequired}
          compareDateWithToday={compareDateWithToday}
        />
      ) : (
        <Web
          dataRows={dataRows}
          selectAll={selectAll}
          onRowCheckBoxChange={handleRowCheckBoxChange}
          onSelectAllChange={handleSelectAllChange}
          orderListState={orderListState}
          hasNfeRequired={hasNfeRequired}
          compareDateWithToday={compareDateWithToday}
        />
      )}
      {loading && (
        <ProgressIndicatorCircular
          className="onboarding-progress-indicator"
          srLabel={i18n.gettext(
            "Buscando informações de progresso do onboarding"
          )}
          srAnnouncement={i18n.gettext("Carregando...")}
          modifier="fullscreen"
        />
      )}
    </>
  );
};
