import { useEffect, useState, type FC } from "react";

import { useI18n } from "nordic/i18n";
import { Container, Flex } from "../../components/grid";

import { ProgressIndicatorCircular } from "@andes/progress-indicator-circular";
import { Button } from "@andes/button";

import { disableMenu, disableNavbar } from "../../utils/handlePortalNavigation";

import type {
  OnboardingResponseProps,
  OnboardingResponsibleProps,
} from "../../services/onboarding/types";

import fixPortalReactCSS from "../../utils/fixPortalReactCSS";
import { ONBOARDING_STEPS, PLATFORM_ORIGIN } from "../../constants/events";

import FieldsForResponsible from "../../containers/onboarding/personData/fieldsByPersonType/fieldsForResponsible";
import {
  FieldsForResponsibleType,
  fieldsForResponsibleSchema,
} from "../../schemas/onboarding/personData";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

import {
  getOnboardingResponsibleAvailability,
  saveOnboardingResponsibleData,
} from "./controller";

import sendGaEvents from "../../utils/sendGaEvents";
import { filterXSS } from "../../utils/sanitizeData";

import "./styles.scss";

const OnboardingResponsible: FC = () => {
  const { i18n } = useI18n();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [saveDataError, setSaveDataError] = useState<string>("");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const methods = useForm<FieldsForResponsibleType>({
    resolver: zodResolver(fieldsForResponsibleSchema),
  });

  const retrieveOnboardingResponsibleAvailability = async () => {
    try {
      const { data }: OnboardingResponseProps =
        await getOnboardingResponsibleAvailability();

      return data?.success;
    } catch (error) {
      console.error(error);
    }
  };

  const handleAvailability = async () => {
    try {
      setIsLoading(true);

      const isOnboardingResponsibleAvailable =
        (await retrieveOnboardingResponsibleAvailability()) || false;

      if (!isOnboardingResponsibleAvailable) {
        window.location.href = "/seller/dashboard";
        return;
      }

      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSuccessParam = () => {
    const params = new URLSearchParams(document.location.search);
    const redirectUrl = params.get("success")?.replace("http://", "https://");

    const sanitizedRedirectUrl = redirectUrl && filterXSS(redirectUrl, true);

    localStorage.setItem("success", sanitizedRedirectUrl || "");
  };

  const handlePlatformOrigin = () => {
    const urlParams = new URLSearchParams(document.location.search);
    const successParam =
      localStorage.getItem("success") || urlParams.get("success");

    if (!successParam) {
      return;
    }

    const platformOrigin = getPlatformName(successParam);
    const sanitizedPlatformOrigin =
      platformOrigin && filterXSS(platformOrigin, true);

    localStorage.setItem("platformOrigin", sanitizedPlatformOrigin || "");
  };

  const getPlatformName = (input: string) => {
    const formattedInput = input.toLowerCase().replace(/[_\-\s]/g, "");

    const platformNames = Object.values(PLATFORM_ORIGIN);

    for (const platform of platformNames) {
      const formattedPlatform = platform.toLowerCase().replace(/[_\-\s]/g, "");
      if (formattedInput.includes(formattedPlatform)) {
        return platform;
      }
    }

    return null;
  };

  const onSubmit = async (formData: FieldsForResponsibleType) => {
    try {
      setSaveDataError("");
      setButtonLoading(true);

      const { nome, cpf, dataNascimento } = formData || {};

      const OnboardingResponsible: OnboardingResponsibleProps = {
        data: {
          nome_resp: nome,
          doc_resp: cpf,
          dt_nasc_resp: dataNascimento,
        },
      };

      const { data }: OnboardingResponseProps =
        await saveOnboardingResponsibleData(OnboardingResponsible);

      if (data?.success) {
        window.location.href = "/sellers/onboarding-address";
        return;
      }

      setSaveDataError(
        data?.message || "Erro ao salvar dados, tente novamente."
      );
    } catch (error) {
      console.error(error);
    } finally {
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    handleAvailability();

    handleSuccessParam();
    handlePlatformOrigin();
    disableMenu(document);
    disableNavbar(document);

    fixPortalReactCSS(true, "onboarding-responsible");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sendGaEvents({
      event: ONBOARDING_STEPS.responsible,
      CADASTRO_NOVO: true,
      ORIGEM_PLATAFORMA: localStorage.getItem("platformOrigin") || "",
    });
  }, []);

  if (isLoading) {
    return (
      <ProgressIndicatorCircular
        className="onboarding-progress-indicator"
        srLabel={i18n.gettext("Buscando informações do onboarding")}
        srAnnouncement={i18n.gettext("Carregando...")}
      />
    );
  }

  return (
    <div className="onboarding p-3">
      <Container className="onboarding-container">
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="mx-auto"
            style={{ maxWidth: "357px" }}
          >
            <Flex gap={4} direction="column" className="pt-4 personData">
              <FieldsForResponsible errorMessage={saveDataError} />

              <Flex gap={3} align="center" justify="end">
                <Button
                  loading={buttonLoading}
                  srAnnouncement={i18n.gettext("Carregando...")}
                  size="large"
                  type="submit"
                >
                  {i18n.gettext("Salvar")}
                </Button>
              </Flex>
            </Flex>
          </form>
        </FormProvider>
      </Container>

      <div className="onboarding-footer" />
    </div>
  );
};

export default OnboardingResponsible;
