import { FC } from "react";

import { Checkbox } from "@andes/checkbox";
import {
  TableBody,
  TableData,
  TableHead,
  TableRow,
  TableHeader,
  Table,
} from "@andes/table";
import Tooltip from "@andes/tooltip";
import { Flex } from "../../components/grid";
import { IOrderList } from "../../services/orderList/types";
import { RenderOcorrencia } from "./renderOcorrencia";

interface WebProps {
  dataRows: IOrderList[];
  selectAll: boolean;
  onRowCheckBoxChange: (index: number, checked: boolean) => void;
  onSelectAllChange: (checked: boolean) => void;
  orderListState: {
    isHighLight: boolean;
  };
  hasNfeRequired: (exige_nota: boolean, tem_nota: boolean) => boolean;
  compareDateWithToday: (date: string) => boolean;
}

export const Web: FC<WebProps> = ({
  dataRows,
  selectAll,
  onRowCheckBoxChange,
  onSelectAllChange,
  orderListState,
  hasNfeRequired,
  compareDateWithToday,
}) => {
  return (
    <div className="custom-table-buffering">
      <Table compact>
        <TableHead sticky>
          <TableHeader className="custom-align">
            <Tooltip
              content={selectAll ? "Desselecionar todos" : "Selecionar todos"}
              trigger="hover"
              type="dark"
            >
              <Checkbox
                className="custom-select-all"
                checked={selectAll}
                onChange={(e) => onSelectAllChange(e.target.checked)}
              />
            </Tooltip>
          </TableHeader>
          <TableHeader>Nome do destinatário</TableHeader>
          <TableHeader>Destino</TableHeader>
          <TableHeader>Valor</TableHeader>
          <TableHeader>Status</TableHeader>
          <TableHeader>Ponto de postagem</TableHeader>
          <TableHeader
            className={orderListState.isHighLight ? "highlight-order-list" : ""}
          >
            Postagem a partir de
          </TableHeader>
        </TableHead>
        <TableBody>
          {dataRows &&
            dataRows.map((row: IOrderList, index: number) => (
              <TableRow
                key={row.id}
                className={
                  row.select
                    ? "custom-selected custom-row"
                    : !hasNfeRequired(row.exige_nota, row.tem_nota) ||
                      row.nfe_invalida
                    ? "custom-disabled custom-row"
                    : "custom-row"
                }
              >
                <TableData data-title="Select" className="custom-align">
                  {!hasNfeRequired(row.exige_nota, row.tem_nota) ||
                  row.nfe_invalida ? (
                    <Tooltip
                      content="Bloqueado por NFe"
                      trigger="hover"
                      type="dark"
                    >
                      <Checkbox
                        disabled={
                          !hasNfeRequired(row.exige_nota, row.tem_nota) ||
                          row.nfe_invalida
                        }
                        checked={row.select}
                        onChange={(e) =>
                          onRowCheckBoxChange(index, e.target.checked)
                        }
                      />
                    </Tooltip>
                  ) : (
                    <Checkbox
                      disabled={!hasNfeRequired(row.exige_nota, row.tem_nota)}
                      checked={row.select}
                      onChange={(e) =>
                        onRowCheckBoxChange(index, e.target.checked)
                      }
                    />
                  )}
                </TableData>
                <TableData data-title="Nome do destinatário">
                  {row.nome_cliente_dest}
                </TableData>
                <TableData data-title="Destino">
                  {row.cidade_dest} - {row.estado_dest}
                </TableData>
                <TableData data-title="Valor">
                  <span className="text-nowrap">R$ {row.vlr_a_pagar}</span>
                </TableData>
                <TableData data-title="Status">
                  {RenderOcorrencia({
                    descricao_ocorrencia: row.descricao_ocorrencia,
                    tem_nota: row.tem_nota,
                    exige_nota: row.exige_nota,
                    nfe_invalida: row.nfe_invalida,
                    numero_cli: row.numero_cli,
                  })}
                </TableData>
                <TableData data-title="Ponto de postagem">
                  <Flex direction="row" align="center" justify="start" gap={1}>
                    <Tooltip
                      content={
                        row.place.agencia
                          ? "Agência Correios"
                          : "Agência Kangu ou Mercado Livre"
                      }
                      trigger="hover"
                      type="dark"
                      side="topLeft"
                    >
                      <img
                        src={row.place.logo}
                        alt={row.place.name}
                        className={
                          row.place.agencia
                            ? "custom-icon-correios"
                            : "custom-icon-kangu"
                        }
                      />
                    </Tooltip>
                    {row.place.name}
                  </Flex>
                </TableData>
                <TableData
                  data-title="Postagem a partir de"
                  className={
                    orderListState.isHighLight ? "highlight-order-list" : ""
                  }
                >
                  {compareDateWithToday(row.pickup_date)
                    ? row.pickup_date
                    : "Hoje"}
                </TableData>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};
