import {useEffect, useState, type FC, useCallback} from "react";
import type {GetDataProps} from "../../../services/loginMeli/compareData/types";

import {retrieveUserMatchData} from "./controller";

import Layout from "../../../components/layout";
import InformativeCard from "../../../containers/loginMeli/compareData/informativeCard";

import "./styles.scss";
import {snackbarStateAtom, SnackbarStateProps} from "../../../states/snackbarState";
import {useAtom} from "jotai";
import {DataFetchService} from "../../../services/types";
import Loading from "../../../components/loading";
import sendGaEvents from "../../../utils/sendGaEvents";
import { MATCH_MELI } from "../../../constants/events";
import { get } from "../../../utils/cookies";

const CompareData: FC = () => {
    const [userMatchData, setUserMatchData] = useState<GetDataProps>();
    const [_, setSnackbar] = useAtom<SnackbarStateProps>(snackbarStateAtom);
    const [showLoading, setshowLoading] = useState(false);

    const showSnackbarErrors = useCallback(
        (error: unknown) => {
            const getError = error as DataFetchService;
            let message = "";

            if (getError?.exception) {
                message = getError?.exception?.message;
            } else if (error instanceof Error) {
                message = error.message;
            }

            setSnackbar((prev) => ({
                ...prev,
                message,
                color: "red",
                show: true,
            }));
        },
        [setSnackbar]
    );

    const handleUserMatchData = async () => {
        setshowLoading(true);
        try {
            const response = await retrieveUserMatchData();

            if (!response || response?.error) {
                throw new Error(response?.message);
            }

            setUserMatchData({
                fullName: response.fullName,
                email: response.email,
                document: response.document,
                phoneNumber: response.phoneNumber,
                address: response.address,
                type: response.type,
            });
        } catch (error) {
            showSnackbarErrors(error);
        } finally {
            setshowLoading(false);
        }
    };

    useEffect(() => {
        handleUserMatchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!userMatchData) {
            return;
        }

        sendGaEvents({
            event: MATCH_MELI,
            userId: get("user_id") || "",
            CADASTRO_NOVO: userMatchData?.type === "create",
            ORIGEM_PLATAFORMA: localStorage.getItem("platformOrigin") || "",
        });
    }, [userMatchData]);

    if (showLoading) {
        return (
            <Layout pageTitle="">
                <div className='loading'>
                    <Loading id="loading" ariaLabel="Carregando" show={showLoading}/>
                </div>
            </Layout>
        );
    }

    return (
        <Layout
            pageTitle={
                userMatchData?.type === "create"
                    ? "Confirmar dados"
                    : "Dados atualizados"
            }
        >
            <InformativeCard
                titleMessage={
                    userMatchData?.type === "create"
                        ? "Pronto! Sua conta Kangu foi criada com sucesso. Ao fazer isso, você autorizou o uso dos dados abaixo"
                        : "Foram atualizados os seguintes dados na sua conta Kangu:"
                }
                fullName={userMatchData?.fullName}
                email={userMatchData?.email}
                document={userMatchData?.document}
                phoneNumber={userMatchData?.phoneNumber}
                address={userMatchData?.address}
                buttonMessage={
                    userMatchData?.type === "create" ? "Entendido" : "Continuar"
                }
                showTerms={userMatchData?.type === "create"}
            />
        </Layout>
    );
};

export default CompareData;
