import type { FC } from "react";
import { Routes, Route } from "react-router-dom";

import MyAccess from "./pages/myAccess";
import UpgradeNivel from "./pages/upgradeNivel";
import SendDoc from "./pages/sendDocument";
import UseDc from "./pages/useDc";
import LoginMeli from "./pages/loginMeli/login";
import Login from "./pages/login";
import OrderList from "./pages/orderList";
import AccountConnection from "./pages/loginMeli/accountConnection";
import CompareData from "./pages/loginMeli/compareData";
import Onboarding from "./pages/onboarding";
import OnboardingAddress from "./pages/onboardingAddress";
import PlannedShipment from "./pages/plannedShipment";
import AboutMeliRegister from "./pages/aboutMeliRegister";
import NewAccount from "./pages/loginMeli/newAccount";
import ErroMatch from "./pages/loginMeli/erroMatch";
import OnboardingResponsible from "./pages/onboardingResponsible";

export const Router: FC = () => {
  return (
    <Routes>
      <Route path="/sellers/meu-acesso/" element={<MyAccess />} />
      <Route path="/sellers/solicitar-novo-nivel/" element={<UpgradeNivel />} />
      <Route path="/sellers/documento-enviado/" element={<SendDoc />} />
      <Route path="/sellers/usar-dc/" element={<UseDc />} />
      <Route path="/sellers/login-meli/login/" element={<LoginMeli />} />
      <Route path="/login/" element={<Login />} />
      <Route
        path="/sellers/login-meli/account-status-connection/"
        element={<AccountConnection />}
      />
      <Route
        path="/sellers/login-meli/compare-data/"
        element={<CompareData />}
      />
      <Route path="/sellers/order-list/" element={<OrderList />} />
      <Route path="/sellers/onboarding/" element={<Onboarding />} />
      <Route
        path="/sellers/onboarding-address/"
        element={<OnboardingAddress />}
      />
      <Route path="/sellers/planned-shipment/" element={<PlannedShipment />} />
      <Route
        path="/sellers/about-meli-register/"
        element={<AboutMeliRegister />}
      />
      <Route path="/sellers/login-meli/new-account" element={<NewAccount />} />
      <Route path="/sellers/login-meli/erro-match" element={<ErroMatch />} />
      <Route path="/sellers/onboarding-responsavel" element={<OnboardingResponsible />} />
    </Routes>
  );
};
