import { FC } from "react";
import { useI18n } from "nordic/i18n";

import Card, { CardContent } from "@andes/card";
import { Title, Text } from "@andes/typography";
import { Col, Flex, Row } from "../../../../components/grid";

import TextField from "@andes/textfield";
import TextFieldWithMask from "../../../../components/textFieldWithMask";

import { useFormContext, Controller } from "react-hook-form";
import { FieldsForResponsibleType } from "../../../../schemas/onboarding/personData";
import { cpfMask, dateMask } from "../../../../constants/masks";

interface FieldsForResponsibleProps {
  errorMessage?: string;
}

const FieldsForResponsible: FC<FieldsForResponsibleProps> = ({
  errorMessage,
}) => {
  const { i18n } = useI18n();

  const { control, formState, register } =
    useFormContext<FieldsForResponsibleType>();

  return (
    <>
      <Title component="h6" size="xs" weight="semibold">
        {i18n.gettext("Preencha os dados do responsável pelo CNPJ")}
      </Title>

      <Card shadow="elevated">
        <CardContent>
          <Flex gap={4} direction="column">
            <Row gap={4}>
              <Col size={12} sm>
                <TextField
                  label="Nome"
                  placeholder="Nome do responsável"
                  modifier={formState.errors.nome ? "error" : undefined}
                  helper={formState.errors.nome?.message}
                  {...register("nome")}
                />
              </Col>
            </Row>

            <Row gap={4}>
              <Col size={12} sm>
                <Controller
                  name="cpf"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextFieldWithMask
                      {...field}
                      maskOptions={cpfMask}
                      label="CPF do responsável"
                      placeholder="000.000.000-00"
                      modifier={formState.errors.cpf ? "error" : undefined}
                      helper={formState.errors.cpf?.message}
                      className="w-100"
                    />
                  )}
                />
              </Col>

              <Col size={12} sm>
                <Controller
                  name="dataNascimento"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextFieldWithMask
                      {...field}
                      maskOptions={dateMask}
                      label="Data de nascimento"
                      placeholder="00/00/0000"
                      modifier={
                        formState.errors.dataNascimento ? "error" : undefined
                      }
                      helper={formState.errors.dataNascimento?.message}
                      className="w-100"
                    />
                  )}
                />
              </Col>
            </Row>

            {errorMessage && (
              <Text size="s" weight="semibold" color="negative">
                {i18n.gettext(errorMessage)}
              </Text>
            )}
          </Flex>
        </CardContent>
      </Card>
    </>
  );
};

export default FieldsForResponsible;
