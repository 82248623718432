import { useEffect, useState, type FC } from "react";

import { useI18n } from "nordic/i18n";
import Typography, {
  Title,
  Text,
  TextList,
  TextListItem,
} from "@andes/typography";
import { Col, Flex, Row } from "../../../components/grid";

import { Button } from "@andes/button";
import { useWizard } from "react-use-wizard";

import { CardAsRadio, CardAsRadioItem } from "../../../components/cardAsRadio";

import { ShippingTypeEnum } from "./enum";
import { PersonTypeEnum } from "../personType/enum";

import {
  onboardingStateAtom,
  type OnboardingStateProps,
} from "../../../states/onboarding";
import { useAtom } from "jotai";
import { saveOnboardingProgress } from "../../../pages/onboarding/controller";
import { OnboardingProgressProps } from "../../../services/onboarding/types";
import sendGaEvents from "../../../utils/sendGaEvents";
import { ONBOARDING_STEPS } from "../../../constants/events";

const ShippingType: FC = () => {
  const { i18n } = useI18n();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const { nextStep, goToStep } = useWizard();

  const [onboardingState, setOnboardingState] =
    useAtom<OnboardingStateProps>(onboardingStateAtom);

  const handleOnboardingProgress = async () => {
    try {
      if (!onboardingState.shippingType) {
        return;
      }

      setButtonLoading(true);

      const onboardingProgress: OnboardingProgressProps = {
        data: {
          step: onboardingState.shippingType === ShippingTypeEnum.DC ? 1 : 2,
          st_declaracao:
            onboardingState.shippingType === ShippingTypeEnum.DC ? "S" : "N",
          tp_perfil: onboardingState.personType,
        },
      };

      await saveOnboardingProgress(onboardingProgress);
    } catch (error) {
      console.error(error);
    } finally {
      setButtonLoading(false);
    }
  };

  const handleSelectedOption = (option: ShippingTypeEnum) => {
    setOnboardingState((prevState) => ({
      ...prevState,
      stepIndex: option === ShippingTypeEnum.DC ? 1 : 2,
      shippingType: option,
      personType:
        option === ShippingTypeEnum.NF ? PersonTypeEnum.CNPJ : undefined,
    }));
  };

  const handleNextStep = async () => {
    await handleOnboardingProgress();

    onboardingState.shippingType === ShippingTypeEnum.DC
      ? nextStep()
      : goToStep(2);
  };

  useEffect(() => {
    sendGaEvents({
      event: ONBOARDING_STEPS.shippingType,
      ORIGEM_PLATAFORMA: localStorage.getItem("platformOrigin") || "",
    });
  }, []);

  return (
    <Flex gap={4} direction="column" className="pt-4 shippingType">
      <Title component="h6" size="xs" weight="semibold">
        {i18n.gettext(
          "Selecione com qual documento você prefere fazer seus envios"
        )}
      </Title>

      <CardAsRadio>
        <CardAsRadioItem
          shadowType="elevated"
          title={
            <Flex
              gap={3}
              direction="column"
              justify="center"
              className="shippingType-cardAsRadioItem-title"
            >
              <img
                src="/public/images/kangu/onboarding-shipping-type-dc.svg"
                alt="Declaração de Conteúdo e Nota Fiscal"
                height={100}
              />
              <Text size="m" weight="semibold">
                {i18n.gettext("Declaração de Conteúdo e Nota Fiscal")}
              </Text>
            </Flex>
          }
          content={
            <>
              <Text size="s">{i18n.gettext("Disponível para:")}</Text>
              <TextList
                size="s"
                srLabel={i18n.gettext("Disponível para: ")}
                markerType="check"
                markerColor="positive"
              >
                <TextListItem>{i18n.gettext("Pessoa Física")}</TextListItem>
                <TextListItem>
                  {i18n.gettext("Microempreendedor Individual (MEI)")}
                </TextListItem>
              </TextList>
            </>
          }
          onClick={() => handleSelectedOption(ShippingTypeEnum.DC)}
          checked={onboardingState.shippingType === ShippingTypeEnum.DC}
        />

        <CardAsRadioItem
          shadowType="elevated"
          title={
            <Flex
              gap={3}
              direction="column"
              justify="center"
              className="shippingType-cardAsRadioItem-title"
            >
              <img
                src="/public/images/kangu/onboarding-shipping-type-nf.svg"
                alt="Nota Fiscal"
                height={100}
              />

              <Text size="m" weight="semibold">
                {i18n.gettext("Nota Fiscal")}
              </Text>
            </Flex>
          }
          content={
            <>
              <Text size="s">{i18n.gettext("Disponível para:")}</Text>
              <TextList
                size="s"
                srLabel={i18n.gettext("Disponível para:")}
                markerType="check"
                markerColor="positive"
              >
                <TextListItem>{i18n.gettext("Pessoa Jurídica")}</TextListItem>
              </TextList>
            </>
          }
          onClick={() => handleSelectedOption(ShippingTypeEnum.NF)}
          checked={onboardingState.shippingType === ShippingTypeEnum.NF}
        />
      </CardAsRadio>

      <Row align="center" justify="end">
        <Col size={12} md className="order-1 order-md-0">
          <Text size="s" color="secondary">
            {i18n.gettext(
              "Escolha uma das opções abaixo para realizar seus envios comerciais ou pessoais. Se ainda estiver em dúvida, "
            )}
            <Typography
              component="a"
              color="link"
              href="https://ajuda.kangu.com.br/hc/pt-br/articles/4416035437207-Quais-documentos-eu-preciso-ter-para-fazer-um-envio"
              target="_blank"
              size="s"
            >
              saiba mais.
            </Typography>
          </Text>
        </Col>
        <Col auto className="order-0 order-md-1">
          <Button
            loading={buttonLoading}
            srAnnouncement={i18n.gettext("Carregando...")}
            disabled={!onboardingState.shippingType}
            size="large"
            className="ms-auto"
            onClick={() => handleNextStep()}
          >
            {i18n.gettext("Selecionar")}
          </Button>
        </Col>
      </Row>
    </Flex>
  );
};

export default ShippingType;
