import { type FC, useCallback, useEffect, useState } from "react";

import SuccessCard from "./successCard";
import RequestCard from "./requestCard";
import AlreadyInUseCard from "./alreadyInUseCard";
import { getDataProfile } from "../../pages/useDc/controller";
import { ProfileInfo } from "../../services/useDc/types";
import { useAtom } from "jotai";
import { UseDcStateProps, useDcStateAtom } from "../../states/useDc";
import sendGaEvents from "../../utils/sendGaEvents";

const ActionCard: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [alreadyInUse, setAlreadyInUse] = useState<boolean>(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [useSameCPF, setUseSameCPF] = useState<boolean>(true);

  const [useDcState, setUseDcState] = useAtom<UseDcStateProps>(useDcStateAtom);

  const retrieveProfileInfo = useCallback(async (): Promise<void> => {
    const { data }: ProfileInfo = await getDataProfile();

    if (!data) {
      return;
    }

    const { exibe, tipo, cpfCadastrado, documento, nome } = data;

    if (!exibe) {
      window.location.href = "/seller/dashboard";
      return;
    }

    setUseDcState({
      ...useDcState,
      exibe,
      cpfCadastrado,
      tipo,
      documento,
      nome,
    });

    setAlreadyInUse(tipo === "fisica");

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    retrieveProfileInfo();

    if (!document) {
      return;
    }

    document.title = isFormSubmitted
      ? "Cadastro alterado!"
      : alreadyInUse
      ? "Conta com Declaração de conteúdo"
      : "Utilizar Declaração de conteúdo";

    if (document.title !== "Utilizar Declaração de conteúdo") {
      sendGaEvents({ event: "page_view" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormSubmitted, alreadyInUse]);

  return (
    !isLoading &&
    (isFormSubmitted ? (
      <SuccessCard />
    ) : alreadyInUse ? (
      <AlreadyInUseCard />
    ) : (
      useSameCPF && (
        <RequestCard
          setUseSameCPF={setUseSameCPF}
          setIsFormSubmitted={setIsFormSubmitted}
        />
      )
    ))
  );
};

export default ActionCard;
