import { FieldsForAddressType } from "../../schemas/onboarding/address";
import Service from "../Service";

import {
  OnboardingProgressProps,
  OnboardingResponseProps,
  OnboardingUserMeliAddressProps,
  OnboardingValidatePersonZipcodeProps,
  OnboardingSavePersonAddressProps,
  OnboardingResponsibleProps,
} from "./types";

export default class OnboardingService {
  static async getOnboardingAvailability(): Promise<OnboardingResponseProps> {
    const service = new Service();
    const response = (await service.doGet(
      "/ca/pessoa/verify-onboarding"
    )) as unknown;

    return response as Promise<OnboardingResponseProps>;
  }

  static async getProgress(): Promise<OnboardingProgressProps> {
    const service = new Service();
    const response = (await service.doGet(
      "/ca/pessoa/get-person-progress"
    )) as unknown;

    return response as Promise<OnboardingProgressProps>;
  }

  static async saveProgress(
    data: OnboardingProgressProps
  ): Promise<OnboardingResponseProps> {
    const service = new Service();

    const response = (await service.doPost(
      "/ca/pessoa/save-person-progress",
      data
    )) as unknown;

    return response as Promise<OnboardingResponseProps>;
  }

  static async verifyData(
    data: OnboardingProgressProps
  ): Promise<OnboardingResponseProps> {
    const service = new Service();
    const response = (await service.doPost(
      "/ca/pessoa/verify-person-onboarding-data",
      data
    )) as unknown;

    return response as Promise<OnboardingResponseProps>;
  }

  static async saveData(
    data: OnboardingProgressProps
  ): Promise<OnboardingResponseProps> {
    const service = new Service();

    const response = (await service.doPost(
      "/ca/pessoa/save-person-onboarding",
      data
    )) as unknown;

    return response as Promise<OnboardingResponseProps>;
  }

  static async getUserMeliAddress(): Promise<OnboardingUserMeliAddressProps> {
    const service = new Service();

    const response = (await service.doGet(
      "/ca/pessoa/get-user-meli-address"
    )) as unknown;

    return response as Promise<OnboardingUserMeliAddressProps>;
  }

  static async validatePersonZipcode(
    zip_code: string
  ): Promise<OnboardingValidatePersonZipcodeProps> {
    const service = new Service();

    const response = (await service.doPost(
      `/ca/pessoa/validate-person-zipcode`,
      {
        zip_code,
      }
    )) as unknown;

    return response as Promise<OnboardingValidatePersonZipcodeProps>;
  }

  static async savePersonAddress(
    data: FieldsForAddressType
  ): Promise<OnboardingSavePersonAddressProps> {
    const service = new Service();

    const response = (await service.doPost("/ca/pessoa/save-person-address", {
      zip_code: data.cep,
      complemento: data.complemento,
      numero: data.numero,
      logradouro: data.logradouro,
      bairro: data.bairro,
      cidade: data.cidade,
    })) as unknown;

    return response as Promise<OnboardingSavePersonAddressProps>;
  }

  static async getOnboardingResponsibleAvailability(): Promise<OnboardingResponseProps> {
    const service = new Service();
    const response = (await service.doGet(
      "/ca/pessoa/verify-onboarding-responsavel"
    )) as unknown;

    return response as Promise<OnboardingResponseProps>;
  }

  static async saveResponsibleData(
    data: OnboardingResponsibleProps
  ): Promise<OnboardingResponseProps> {
    const service = new Service();

    const response = (await service.doPost(
      "/ca/pessoa/save-person-responsavel-onboarding",
      data
    )) as unknown;

    return response as Promise<OnboardingResponseProps>;
  }
}
