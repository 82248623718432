import OnboardingService from "../../services/onboarding";

import type {
  OnboardingProgressProps,
  OnboardingResponseProps,
} from "../../services/onboarding/types";

export const getOnboardingResponsibleAvailability =
  async (): Promise<OnboardingResponseProps> => {
    const response =
      await OnboardingService.getOnboardingResponsibleAvailability();

    return response;
  };

export const saveOnboardingResponsibleData = async (
  data: OnboardingProgressProps
): Promise<OnboardingResponseProps> => {
  const response = await OnboardingService.saveResponsibleData(data);

  return response;
};
