import { useEffect } from "react";
import { useI18n } from "nordic/i18n";

import Card, { CardContent } from "@andes/card";
import { Title, Text } from "@andes/typography";
import { Button } from "@andes/button";

import Layout from "../../components/layout";
import { Row, Col } from "../../components/grid";
import { filterXSS } from "../../utils/sanitizeData";

const AboutMeliRegister = () => {
  const { i18n } = useI18n();

  const handleClick = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const successParam = localStorage.getItem("success");

    if (successParam) {
      queryParams.set("success", successParam);
    }

    const queryString = queryParams.toString();
    const redirectUrl = new URL(
      "/auth/mercadolivre/login-mercadolivre-redirect",
      window.location.origin
    );

    if (queryString) {
      redirectUrl.search = queryString;
    }

    const sanitizedRedirectUrl = filterXSS(redirectUrl.toString(), true);

    window.location.href = sanitizedRedirectUrl;
  };

  const handleSuccessParam = () => {
    const params = new URLSearchParams(document.location.search);
    const redirectUrl = params.get("success")?.replace("http://", "https://");

    const sanitizedRedirectUrl = redirectUrl && filterXSS(redirectUrl, true);

    localStorage.setItem("success", sanitizedRedirectUrl || "");
  };

  useEffect(() => {
    handleSuccessParam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout pageTitle="Redirecionamento de cadastro">
      <Card paddingSize={32}>
        <CardContent>
          <Row gap={3} align="center" justify="center">
            <Col auto>
              <img
                src="/public/layout/kangu/images/about-meli-register-holding-hands.svg"
                alt="Ilustração de mãos dadas"
                width={323}
                height={302}
                className="w-100"
              />
            </Col>

            <Col md>
              <Row gap={4} align="center">
                <Title component="h6" size="l" weight="semibold">
                  {i18n.gettext(
                    "Para acessar a Kangu, estamos redirecionando você ao Mercado Livre"
                  )}
                </Title>

                <Text size="m">
                  {i18n.gettext(
                    "A Kangu é uma empresa do Mercado Livre. Por esse motivo, você precisa de uma conta Mercado Livre para prosseguir."
                  )}
                </Text>

                <Text size="m">
                  {i18n.gettext(
                    "Caso já possua, seus dados para criar uma conta Kangu serão preenchidos automaticamente."
                  )}
                </Text>

                <Col size={12} md="auto">
                  <Button size="large" fullWidth onClick={() => handleClick()}>
                    {i18n.gettext("Continuar")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardContent>
      </Card>
    </Layout>
  );
};

export default AboutMeliRegister;
