import DOMPurify from "dompurify";

const isJavaScriptProtocol =
  /^[\u0000-\u001F ]*j[\r\n\t]*a[\r\n\t]*v[\r\n\t]*a[\r\n\t]*s[\r\n\t]*c[\r\n\t]*r[\r\n\t]*i[\r\n\t]*p[\r\n\t]*t[\r\n\t]*\:/i;

export function sanitizeData<T>(data: T): T {
  if (typeof data === "string") {
    return DOMPurify.sanitize(data) as unknown as T;
  } else if (typeof data === "object" && data !== null) {
    const sanitizedObject: any = Array.isArray(data) ? [] : {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        sanitizedObject[key] = sanitizeData((data as any)[key]);
      }
    }
    return sanitizedObject;
  }
  return data;
}

export function filterXSS(input: string, deepSanitize: boolean = false, defaultValue?: string): string {
  if (deepSanitize && isJavaScriptProtocol.test(input)) {
    return defaultValue || "";
  }

  const oldValue = input;
  const element = document.createElement("div");
  if (element.textContent === undefined) {
    element.textContent = input;
  } else {
    element.innerText = input;
  }

  if (element.innerHTML !== oldValue && defaultValue) {
    return sanitizeData(defaultValue) as string;
  }

  return sanitizeData(element.innerHTML) as string;
}
